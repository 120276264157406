import { render, staticRenderFns } from "./manage_organization.vue?vue&type=template&id=4cdb2881&"
import script from "./manage_organization.vue?vue&type=script&lang=js&"
export * from "./manage_organization.vue?vue&type=script&lang=js&"
import style0 from "./manage_organization.vue?vue&type=style&index=0&id=4cdb2881&prod&media=screen&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports